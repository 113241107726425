import React, { useState } from 'react';
import { Button, Form, Header, Segment } from 'semantic-ui-react';
import { API, showError, showSuccess } from '../../helpers';

const AddUser = () => {
  const originInputs = {
    username: '',
    display_name: '',
    password: '',
  };
  const [inputs, setInputs] = useState(originInputs);
  const { username, display_name, password } = inputs;

  const handleInputChange = (e, { name, value }) => {
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  };

  const submit = async () => {
    if (inputs.username === '' || inputs.password === '') return;
    const res = await API.post(`/api/user/`, inputs);
    const { success, message } = res.data;
    if (success) {
      showSuccess('Учетная запись пользователя успешно создана!');
      setInputs(originInputs);
    } else {
      showError(message);
    }
  };

  return (
    <>
      <Segment>
        <Header as="h3">Создать новую учетную запись пользователя</Header>
        <Form autoComplete="off">
          <Form.Field>
            <Form.Input
              label="Имя пользователя"
              name="username"
              placeholder={'Пожалуйста, введите имя пользователя'}
              onChange={handleInputChange}
              value={username}
              autoComplete="off"
              required
            />
          </Form.Field>
          <Form.Field>
            <Form.Input
              label="Отображаемое имя"
              name="display_name"
              placeholder={'Пожалуйста, введите отображаемое имя'}
              onChange={handleInputChange}
              value={display_name}
              autoComplete="off"
            />
          </Form.Field>
          <Form.Field>
            <Form.Input
              label="Пароль"
              name="password"
              type={'password'}
              placeholder={'Пожалуйста, введите пароль'}
              onChange={handleInputChange}
              value={password}
              autoComplete="off"
              required
            />
          </Form.Field>
          <Button positive type={'submit'} onClick={submit}>
            Отправить
          </Button>
        </Form>
      </Segment>
    </>
  );
};

export default AddUser;
